h1,h2,h3,h4,h5,p,a{
    font-family: 'Cera Pro';
    font-weight: 400;
}
h1,h2,h3,h4,h5{

    color:rgba(240, 156, 96, 1);
}
p,a{
    font-weight: 300;
    color:white;
}
body small{
    color:rgba(255,255,255,0.5);
}
body{
    background: black;
}
hr{
    background: white;
}
.btn-light{
    color: rgba(0, 0, 0, 0.8);
}
.yButton.bottom{
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-radius: 100px;
    transform: scale(0.7);
    left: 0px !important;
    bottom: 0px !important;
}

.form-control option {
    color: black !important;
  }

.btn{
    font-variant: all-small-caps;
    border-radius: 10px;

    padding-left: 12px;
padding-right: 12px;
transition: .3s;
font-weight: 300;
padding-bottom: 4px;
padding-top: 3px;
}
hr{
    opacity: 0.05;
}

.alert{
backdrop-filter:blur(10px);
}

.alert-danger{
    background: rgba(249, 199, 199, 0.43);
    border-color: rgba(181, 108, 108, 0.5);
}


#root .MuiDataGrid-root .MuiDataGrid-columnHeader:focus,#root .MuiDataGrid-root .MuiDataGrid-cell:focus{
    outline: none !important;
}
.form-control{
    border-radius: 10px;
    font-size: 14px;
    height: 32px;
}

small{
    font-size: 12px;
color: rgba(0,0,0,0.5);
font-weight: 300;
}

.btn-primary {
    color: #f9f2ea;
    background-color:#9e896e;
    border-color: #988160;
}
.btn-primary:hover {
    color: #f9f2ea;
    background-color: #c29b69;
    border-color: #ab7f46;
}
.btn-primary:active {
    color: white;
    background-color: #c2965c;
    border-color: #ab7f46;
}

.btn-danger {
    color: #772828;
background-color: transparent;
border-color: transparent;
}
.bg-danger{
    background-color: rgba(221, 53, 69, 0.14)!important;
    transition: .3s;
}

.btn-danger:hover {
    color: #772828;
background-color: rgba(255, 0, 0, 0.1);
border-color: transparent;
    }

    ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
        color:    rgba(255,255,255,0.6) !important;
    }
    
    .MuiButton-root.MuiButton-textPrimary {
        background-color: rgba(255, 181, 129, 0.19) !important;
        color: rgba(255, 181, 129, 1) !important;
    }
    .MuiButton-root.MuiButton-textPrimary:hover {
        background-color: rgba(255, 181, 129, 1) !important;
color: black !important;
    }

    .MuiButton-root.MuiButton-textWarning {
        background-color: rgba(237, 108, 2, 0.04) !important;
    }
    .MuiButton-root.MuiButton-textWarning:hover {
        background-color: rgba(237, 108, 2, 0.1) !important;
    }

    .MuiButton-root.MuiButton-textError {
        background-color: rgba(211, 47, 47, 0.04) !important;
    }
    .MuiButton-root.MuiButton-textError:hover {
        background-color: rgba(211, 47, 47, 0.1) !important;
    }
    .MuiButton-root.MuiButton-textSuccess {
        background-color: rgba(46, 125, 50, 0.04) !important;
    }
    .MuiButton-root.MuiButton-textSuccess:hover {
        background-color: rgba(46, 125, 50, 0.1) !important;
    }

    .MuiButton-root.MuiButton-text{
        overflow: hidden;
    }
    .css-1vcyltc-MuiAutocomplete-listbox .MuiAutocomplete-option{
        font-size: 12px;;
    }

    thead tr td{
        font-size: 11px !important;
    }
    
    ::-webkit-scrollbar {
        width: 5px;
        background-color: rgba(255,255,255, 0);
        
      }
      
      
      ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        border: 1px solid transparent;
        background-color:  rgba(255,255,255, 1);
      }
      ::-webkit-scrollbar-track{
       
        background-color:  rgba(255,255,255, 0);
      }
      

      .hiddenScroll::-webkit-scrollbar-thumb{
        background-color:  rgba(255,255,255, 0);
        width: 1px;
        height: 1px;;
      }
      .hiddenScroll ::-webkit-scrollbar {
        width: 1px;
        background-color: rgba(255,255,255, 0);
      }
  body ul:focus,
  body:focus,
  body div:focus,
  body a:focus,
  body p:focus,
  body a{
    outline: none !important;
    text-decoration: none !important;
  }



  #root .MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-root.MuiInputBase-formControl:before{
    border-bottom: none !important;
  }

  #root .MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-root.MuiInputBase-formControl{
      border-radius: 5px;
      overflow: hidden;
  }

  body .form-control{
    background:rgba(255,255,255,0.05) !important ;
    border-color:rgba(255,255,255,0.05) !important ;
    color:white !important ;
  }


  .MuiTabs-flexContainer button{
    color: rgba(255, 181, 129, 1) !important
  }
  .MuiTabs-scroller .MuiTabs-indicator {
    background-color: rgba(255, 181, 129, 1) !important
  }


  .MuiButtonBase-root.MuiTab-root.MuiTab-labelIcon.MuiTab-textColorPrimary{
    min-height: 42px;
  }


  .MuiFilledInput-input.MuiInputBase-input.MuiInputBase-inputSizeSmall{
    font-size: 12px;
  }

  @font-face {
    font-family: 'Cera Pro';
    src: url('./fonts/CeraPro-Light.eot');
    src: local('Cera Pro Light'), local('CeraPro-Light'),
        url('./fonts/CeraPro-Light.eot?#iefix') format('embedded-opentype'),
        url('./fonts/CeraPro-Light.woff2') format('woff2'),
        url('./fonts/CeraPro-Light.woff') format('woff'),
        url('./fonts/CeraPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('./fonts/CeraPro-MediumItalic.eot');
    src: local('Cera Pro Medium Italic'), local('CeraPro-MediumItalic'),
        url('./fonts/CeraPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/CeraPro-MediumItalic.woff2') format('woff2'),
        url('./fonts/CeraPro-MediumItalic.woff') format('woff'),
        url('./fonts/CeraPro-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('./fonts/CeraPro-RegularItalic.eot');
    src: local('Cera Pro Regular Italic'), local('CeraPro-RegularItalic'),
        url('./fonts/CeraPro-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/CeraPro-RegularItalic.woff2') format('woff2'),
        url('./fonts/CeraPro-RegularItalic.woff') format('woff'),
        url('./fonts/CeraPro-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('./fonts/CeraPro-BlackItalic.eot');
    src: local('Cera Pro Black Italic'), local('CeraPro-BlackItalic'),
        url('./fonts/CeraPro-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/CeraPro-BlackItalic.woff2') format('woff2'),
        url('./fonts/CeraPro-BlackItalic.woff') format('woff'),
        url('./fonts/CeraPro-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('./fonts/CeraPro-Medium.eot');
    src: local('Cera Pro Medium'), local('CeraPro-Medium'),
        url('./fonts/CeraPro-Medium.eot?#iefix') format('embedded-opentype'),
        url('./fonts/CeraPro-Medium.woff2') format('woff2'),
        url('./fonts/CeraPro-Medium.woff') format('woff'),
        url('./fonts/CeraPro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('./fonts/CeraPro-BoldItalic.eot');
    src: local('Cera Pro Bold Italic'), local('CeraPro-BoldItalic'),
        url('./fonts/CeraPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/CeraPro-BoldItalic.woff2') format('woff2'),
        url('./fonts/CeraPro-BoldItalic.woff') format('woff'),
        url('./fonts/CeraPro-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('./fonts/CeraPro-ThinItalic.eot');
    src: local('Cera Pro Thin Italic'), local('CeraPro-ThinItalic'),
        url('./fonts/CeraPro-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/CeraPro-ThinItalic.woff2') format('woff2'),
        url('./fonts/CeraPro-ThinItalic.woff') format('woff'),
        url('./fonts/CeraPro-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('./fonts/CeraPro-LightItalic.eot');
    src: local('Cera Pro Light Italic'), local('CeraPro-LightItalic'),
        url('./fonts/CeraPro-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/CeraPro-LightItalic.woff2') format('woff2'),
        url('./fonts/CeraPro-LightItalic.woff') format('woff'),
        url('./fonts/CeraPro-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('./fonts/CeraPro-Black.eot');
    src: local('Cera Pro Black'), local('CeraPro-Black'),
        url('./fonts/CeraPro-Black.eot?#iefix') format('embedded-opentype'),
        url('./fonts/CeraPro-Black.woff2') format('woff2'),
        url('./fonts/CeraPro-Black.woff') format('woff'),
        url('./fonts/CeraPro-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('./fonts/CeraPro-Bold.eot');
    src: local('Cera Pro Bold'), local('CeraPro-Bold'),
        url('./fonts/CeraPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/CeraPro-Bold.woff2') format('woff2'),
        url('./fonts/CeraPro-Bold.woff') format('woff'),
        url('./fonts/CeraPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('./fonts/CeraPro-Regular.eot');
    src: local('Cera Pro Regular'), local('CeraPro-Regular'),
        url('./fonts/CeraPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('./fonts/CeraPro-Regular.woff2') format('woff2'),
        url('./fonts/CeraPro-Regular.woff') format('woff'),
        url('./fonts/CeraPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('./fonts/CeraPro-Thin.eot');
    src: local('Cera Pro Thin'), local('CeraPro-Thin'),
        url('./fonts/CeraPro-Thin.eot?#iefix') format('embedded-opentype'),
        url('./fonts/CeraPro-Thin.woff2') format('woff2'),
        url('./fonts/CeraPro-Thin.woff') format('woff'),
        url('./fonts/CeraPro-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

